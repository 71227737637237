@import 'style/_variables.scss';

.header {
  background-color: white;
  font-family: 'Montserrat', sans-serif;

  &_logo {
    text-decoration: none;
    cursor: pointer;
  }
  
  &_logoTitle {
    font-size: 2.2rem;
    font-weight: $font-bold;
    background: linear-gradient(118deg, #263571, #263571);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &_menuItems {
    list-style: none;
    margin-top: 6rem;
    padding-left: 3.5rem;
    &__item {
      margin: 2.2rem 0;
      &:last-child {
        margin: 4rem 0;
        .header_menuItems__link {
          color: #E9649B;
        }
      }
    }
    &__link {
      color: $text-grey;
      text-decoration: none;
      font-size: 1.8rem;
    }
    &__linkActive {
      font-weight: $font-bold;
    }
  }
}