@import 'style/_variables.scss';

.loading {
  position: fixed;
  display: grid;
  justify-items: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.9;
  z-index: 999;
}
