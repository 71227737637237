@import 'style/main.scss';

.s {

  &_wrapper {
    @extend .background_gradient;
    @extend .background_gradient__gray;
  }

  &_container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}