
.container {
  display: grid;
  padding: $content-padding-top $content-padding-horizontal 0;
}

.title {
  margin-top: 3.0rem;
  margin-bottom: 0rem;
  font-size: 1.8rem;

  &_log {
    font-size: 2.4rem;
    font-weight: $font-bold;
    margin: 2rem 0 2.5rem 0;
    text-align: center;
  }

  &__small {
    margin-top: .2rem;
    margin-bottom: 0rem;
    font-size: 1.4rem;
    font-weight: $font-regular;
  }

  &__smallBold {
    font-weight: $font-bold;
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }

  &__marginTitle {
    margin: 2.2rem 0 1rem;
  }

  &__err {
    font-size: 1.8rem;
    padding: 1rem 2rem;
    text-align: center;
  }
}

//Custom classes for displaying price with different styles ex. 'cents'
.inputValue {
  font-weight: 700;

  &__int {
    font-size: 3rem;
  }

  &__cent {
    font-size: 2.2rem;
  }

  &__currency {
    font-weight: 1.4rem;
    margin-left: .5rem;
  }
}

// Global reusable classes
.mt-5 {
  margin-top: .5rem;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mt-20 {
  margin-top: 2rem;
}
.mt-25 {
  margin-top: 2.5rem;
}
.mt-30 {
  margin-top: 3rem;
}

.mb-5 {
  margin-bottom: .5rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.mb-20 {
  margin-bottom: 2rem;
}
.mb-25 {
  margin-bottom: 2.5rem;
}
.mb-30 {
  margin-bottom: 3rem;
}

.ml-5 {
  margin-left: .5rem;
}
.ml-10 {
  margin-left: 1rem;
}
.ml-15 {
  margin-left: 1.5rem;
}
.ml-20 {
  margin-left: 2rem;
}
.ml-25 {
  margin-left: 2.5rem;
}
.ml-30 {
  margin-left: 3rem;
}

.mr-5 {
  margin-right: .5rem;
}
.mr-10 {
  margin-right: 1rem;
}
.mr-15 {
  margin-right: 1.5rem;
}
.mr-20 {
  margin-right: 2rem;
}
.mr-25 {
  margin-right: 2.5rem;
}
.mr-30 {
  margin-right: 3rem;
}

.pt-5 {
  padding-top: .5rem;
}
.pt-10 {
  padding-top: 1rem;
}
.pt-15 {
  padding-top: 1.5rem;
}
.pt-20 {
  padding-top: 2rem;
}
.pt-25 {
  padding-top: 2.5rem;
}
.pt-30 {
  padding-top: 3rem;
}

.pb-5 {
  padding-bottom: .5rem;
}
.pb-10 {
  padding-bottom: 1rem;
}
.pb-15 {
  padding-bottom: 1.5rem;
}
.pb-20 {
  padding-bottom: 2rem;
}
.pb-25 {
  padding-bottom: 2.5rem;
}
.pb-30 {
  padding-bottom: 3rem;
}

.pl-5 {
  padding-left: .5rem;
}
.pl-10 {
  padding-left: 1rem;
}
.pl-15 {
  padding-left: 1.5rem;
}
.pl-20 {
  padding-left: 2rem;
}
.pl-25 {
  padding-left: 2.5rem;
}
.pl-30 {
  padding-left: 3rem;
}

.pr-5 {
  padding-right: .5rem;
}
.pr-10 {
  padding-right: 1rem;
}
.pr-15 {
  padding-right: 1.5rem;
}
.pr-20 {
  padding-right: 2rem;
}
.pr-25 {
  padding-right: 2.5rem;
}
.pr-30 {
  padding-right: 3rem;
}
