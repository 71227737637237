.notification {
  &_msgWrapper {
    display: grid;
    grid-template-columns: 3.3rem 1fr;
    align-items: start;
    margin-top: 0.5rem;
  }
  &_icon {
    margin-top: -.3rem;
  }
}