@import '_variables';
@import '_fonts';
@import '_classes';

html, body, #root {
  position: relative;
  height: 100%;
  font-size: 62.5%;
  color: $text-grey;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.background {
  &_gradient {
    height: 100%;
    &__gray {
      background: linear-gradient($bg-gradient-top-gray, $bg-gradient-bottom-gray);
    }
    &__purpure {
      background: linear-gradient($bg-gradient-top-purpure, $bg-gradient-bottom-purpure);
    }
  }
}

.card {
  &__active {
    background: linear-gradient(130deg, $bg-gradient-bottom-purpure, $bg-gradient-top-purpure) !important;
    color: $text-white !important;
  }

  &__activeRotate {
    background: linear-gradient(260deg, $bg-gradient-bottom-purpure, $bg-gradient-top-purpure) !important;
    color: $text-white !important;
  }

  &__disabled {
    border: 2px solid $text-grey-disabled !important;
    
    > span {
      color: $text-grey-disabled !important;
      font-weight: $font-semiBold;
    }
  }
}