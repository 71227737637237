@import 'style/_variables.scss';

.switchWrapper {
  position: relative;
  height: $height-without-appbar;
}
 
.switchWrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
}

.dialog {
  & > div > div {
    color: white;
    background: linear-gradient(175deg, $bg-gradient-top-purpure, $bg-gradient-bottom-purpure);
  }
  &_title > h2 {
    font-size: 2.4rem;
    font-weight: $font-bold;
    text-align: center;
    margin-top: 20px;
    & button {
      position: absolute;
      right: 0;
      top: 0;
      color: white;
      opacity: 0.7;
      & svg {
        font-size: 3rem;
      }
    }
  }
  
  &_content {
    font-size: 1.6rem;
    display: grid;
    text-align: center;
    justify-items: center;
    margin: 20px 35px;
    line-height: 1.4;
  }
  &_link {
    color: white;
    text-decoration: none;
    font-weight: $font-bold;
  }
  &_qrcode {
    margin: 25px;
  }
}